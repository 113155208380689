import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
const missingPageCat = '/assets/missing_image_cat.jpg';

function getArticleImage(article) {
    // return (typeof article._image === 'string' || article._image instanceof String)
    //     ? article._image
    //     : missingPageCat;
    return missingPageCat;
}

export default function Card({ cardData = { articles: [] } }) {
    // Initialize data with a default value so it's never undefined
    const initdata = { articles: [] }
    const [data, setData] = useState(initdata);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const loadMore = async () => {
        const nextPage = page + 1;
        try {
            const portVar = process.env.VITE_FETCH_FETCH_PORT;
            const hostVar = process.env.VITE_FETCH_FE_HOST;
            const endpointVar = process.env.VITE_FETCH_LOCATION;
            const response = await fetch(`http://${hostVar}:${portVar}/${endpointVar}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: 78912, page: nextPage, limit: 10 })
            });
            const newCardData = await response.json();
            setData((prevData) => ({
                ...prevData,
                articles: [...prevData.articles, ...newCardData.articles]
            }));
            setPage(nextPage);
            if (newCardData.articles.length === 0 || newCardData.articles.length < 10) {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error loading more articles:", error);
            setHasMore(false);
        }
    };

    if (data.error) {
        return <div className="error">Failed to load articles. Please try again later.</div>;
    }

    // if (!data.articles || data.articles.length === 0) {
    //     return <div>No articles available at the moment.</div>;
    // }

    // async function fetchInitialData() {
    //     try {
    //         const portVar = process.env.VITE_FETCH_FETCH_PORT;
    //         const hostVar = process.env.VITE_FETCH_FE_HOST;
    //         const endpointVar = process.env.VITE_FETCH_LOCATION;
    //         const apiUrl = `http://${hostVar}:${portVar}/${endpointVar}`;
    //         const response = await fetch(apiUrl, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ id: 78912, page: 1, limit: 10 }),
    //         });
    //         const newData = await response.json();
    //         setData(newData);
    //     } catch (error) {
    //         console.error("Failed to fetch initial articles:", error);
    //     }
    // }


    // useEffect(() => {
    //     if (!cardData || !cardData.articles.length) {
    //         fetchInitialData();
    //     }
    // }, []);

    return (
        <div className="Card CardContainer">

            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading articles...</div>}
                useWindow={true}
                threshold={250}
            >
                {
                    data.articles.map((article, index) => (
                        <div className="Card" key={article.id || index}>
                            <div className="CardBackground" id="Cards">
                                <div className="CardContents">
                                    <a className="CardTitle" href={article._url} target="_blank" rel="noreferrer">
                                        {article._title}
                                    </a>
                                    <div className="CardDiv"><img className="CardImage" src={getArticleImage(article)} alt={article._title} /></div>
                                    <div className="CardText">{article._summary}</div>
                                </div>
                                <div className="CardFooter">
                                    <span>
                                        From the “{article._section}” section of <i>{article._authors}</i>, {article._date}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
            </InfiniteScroll>

            {!hasMore && (
                <div className="end-message" style={{ textAlign: 'center', marginTop: '20px' }}>
                    No more articles for now!
                </div>
            )}
        </div>
    );
}